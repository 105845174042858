var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zr-end-of-week__component"},[_c('v-container',{attrs:{"fluid":_vm.isRealXl}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.isUserLoggedInAndHasRights && !_vm.initialRedirect && _vm.endOfWeekLoaded)?_c('v-card',[_c('v-form',[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"text-h5 Anthrazit--text"},[_vm._v(_vm._s(_vm.$t('title')))])]),_c('v-card-text',[_c('d4y-table',{attrs:{"haveMoreActions":true,"searchLabel":_vm.$t('table_search_label'),"tableHasFilterBtn":false,"tableHeaderText":_vm.tableText,"hasFooterBtn":false,"isFooterBtnDisabled":_vm.isHasSearchData,"loading":_vm.wochenabschluesseIsLoading,"loadingFacet":_vm.loadingFacet,"serverItemsLength":_vm.getWochenabschluesse.total,"items":_vm.items,"mitgliedList":_vm.mitgliedList,"listlogEndWeek":_vm.getterListlogEndWeek,"headers":_vm.tableView==0 ? _vm.headers : _vm.altHeaders,"options":_vm.zrWochenabschluesseSearchParams.dataOption,"isSearchDataEmpty":_vm.isAllFiltersEmpty,"searchMenuObj":_vm.searchMenuObj,"hasBodySlot":false},on:{"update:options":_vm.selectionUpdateOptions,"click:loadViewTable":_vm.selectionViewTable,"click:clear-all-filter":_vm.clearAllFilters,"search:chip":_vm.searchInRealObjects,"click:downloadfile":_vm.downloadFile,"click:sendrabbitmqmess":_vm.sendRabbitMqMess,"remove:chip":_vm.removeChip},scopedSlots:_vm._u([{key:"end_of_week_date",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[item.file_id && item.file_id.startsWith('P') ? 'cursor_pointer' : _vm.tableView == 0 ? 'cursor_pointer' : 'cursor_default'],on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item)}}},[_vm._v(" "+_vm._s(_vm._f("formatDateWithLocale")(item.end_of_week_date,_vm.$i18n.locale))+" ")])]}},{key:"full_name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[item.file_id && item.file_id.startsWith('P') ? 'cursor_pointer' : _vm.tableView == 0 ? 'cursor_pointer' : 'cursor_default'],on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item)}}},[_vm._v(" "+_vm._s(item.full_name)+" ")])]}},{key:"file_title",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"openOnlick":false,"openOnFocus":false,"right":"","color":"white","content-class":"tooltip-border"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"divdisplay",on:{"mouseenter":function($event){return _vm.onMouseOver(item.id)}}},[(item.process_type=='sftp_request')?_c('v-chip',_vm._g(_vm._b({staticClass:"text_chip mt-2",class:[item.file_id && item.file_id.startsWith('P') ? 'cursor_pointer' : 'cursor_default'],attrs:{"x-small":"","color":"#EE7F00","text-color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item)}}},'v-chip',attrs,false),on),[_vm._v(" Zuletzt bereitgestellt am "+_vm._s(_vm._f("formatDateTimeWithLocale")(item.date_event,_vm.$i18n.locale) + ' ('+ item.username + ')')+" ")]):_vm._e()],1)]}}],null,true)},[_c('div',{staticClass:"black--text my-1"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.$t('latest_changes')))]),_c('v-divider',{staticClass:"my-1"}),(_vm.historyFileOfEndWeekList == undefined || _vm.historyFileOfEndWeekList.length == 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('no_entries'))+" ")]):_vm._l((_vm.historyFileOfEndWeekList),function(entry){return _c('div',{key:entry.id,staticClass:"text-body-2"},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"font-weight-bold text-left mr-1"},[_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithLocale")(entry.date_event,_vm.$i18n.locale))+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(entry.useremail))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(entry.process_type))])]),_c('v-divider',{staticClass:"my-1"})],1)})],2)]),_c('div',{staticClass:"divdisplay"},[_c('span',{staticClass:"ml-1",class:[item.file_id && item.file_id.startsWith('P') ? 'cursor_pointer' : 'cursor_default'],on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item)}}},[_vm._v(" "+_vm._s(item.file_title)+" ")])])]}},{key:"file_name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[item.file_id && item.file_id.startsWith('P') ? 'cursor_pointer' : 'cursor_default'],on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item)}}},[_vm._v(" "+_vm._s(item.file_name)+" ")])]}},{key:"more-actions",fn:function(ref){
var item = ref.item;
return [(_vm.existExtention(item.item.mimetype))?_c('d4y-btn',{class:[item.item.file_id && item.item.file_id.startsWith('P') ? 'cursor_pointer' : 'cursor_default'],staticStyle:{"width":"35px"},attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item.item)}}},[_c('v-img',{staticStyle:{"opacity":"0.8"},attrs:{"contain":"","height":34,"width":34,"src":item.item.mimetype == 'application/pdf' ? require('@/assets/'+ _vm.filePDF.src) : item.item.mimetype == 'text/csv' ?  require('@/assets/'+ _vm.fileCSV.src) : item.item.mimetype == 'application/zip' ?  require('@/assets/'+ _vm.fileZIP.src) : ''}})],1):_vm._e()]}},{key:"more-actions-wa",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"width":"90px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('d4y-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","disabled":!(item.item.file_id && item.item.file_id.startsWith('P'))},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item.item)}}},'d4y-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"32","color":"primary"}},[_vm._v(" mdi-download-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Download ")])]),(item.item.providable)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('d4y-btn',_vm._g(_vm._b({staticStyle:{"width":"35px"},attrs:{"icon":"","disabled":_vm.getDisabled(item.item.id, item.item.date_event)},on:{"click":function($event){$event.stopPropagation();return _vm.sendRabbitMqMess(item.item)}}},'d4y-btn',attrs,false),on),[_c('v-img',{attrs:{"contain":"","height":34,"width":34,"src":_vm.getDisabled(item.item.id, item.item.date_event) ? require('@/assets/'+ _vm.bereitsstellenIconDisabled.src) : require('@/assets/'+ _vm.bereitsstellenIcon.src)}})],1)]}}],null,true)},[_c('span',[_vm._v(" Bereitstellen ")])]):_vm._e()],1)]}}],null,false,2546097453)})],1)],1)],1):(_vm.isUserLoggedInAndHasRights === false && (_vm.zrNummer === null || !_vm.isUserHasRightsForEndOfWeek) && !_vm.initialRedirect && _vm.endOfWeekLoaded)?_c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2","max-width":"600"}},[_c('v-card-title',[_c('span',{staticClass:"text-h4 font-weight-light"},[_vm._v(_vm._s(_vm.$t('no_access_title')))])]),_c('v-card-text',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('no_access_text'))+" ("),_c('a',{staticClass:"primary--text",attrs:{"href":"mailto:support@meinvme.de"}},[_vm._v("support@meinvme.de")]),_vm._v(") ")])],1)],1):_c('div',[_c('v-progress-circular',{attrs:{"size":70,"width":7,"indeterminate":"","color":"primary"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }