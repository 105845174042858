import { ROUTES } from '@/router/routesEnum';
import changeVoucher, { ChangeVoucher } from '@/shared/model/changeVoucher';
import cngvoucherSearchData, {
  ChangeVoucherSearchData,
} from '@/shared/model/smallPayloadModels/changeVoucherSearchData';
import { BackendHelper, DefaultBackendHelper } from './../../../shared/utils/backendHelper';
import changeVoucherSearchData from '@/shared/model/smallPayloadModels/changeVoucherSearchData';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST } from '@/shared/utils/Constants';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { UserData } from '@/shared/model/userData';
import GeneralUtils from '@/shared/utils/generalUtils';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { DefaultViewHelper } from '@/views/ViewHelper';
import { changevoucherTypeList } from '@/shared/model/smallPayloadModels/changevoucherType';
import SupplierTooltip from '../zr-documents/search-form/supplier-tooltip/supplier-tooltip.vue';
const name = 'changeVouchers-view';
const logger = new Logger(name);
const changeVoucherModule = namespace('changeVoucher');
const authModule = namespace('auth');
const userDataModule = namespace('userData');

@Component({ name: name, components: { D4yTable, SupplierTooltip } })
export default class ChangeVouchersComponent extends Vue {
  @authModule.Action('canSeeAll')
  private actionCanSeeAll!: any;
  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserSuperAdmin')
  private isUserSuperAdmin!: any;
  @authModule.Getter('isUserOnlyGsAndHasTestZr')
  private isUserOnlyGsAndHasTestZr!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;
  @authModule.Getter('isUserSupport')
  private isUserSupport!: any;
  @authModule.Getter('isInitialRedirect')
  private isInitialRedirectGetter!: any;
  @authModule.Getter('supplierTooltipInfo')
  private supplierTooltipInfo!: any;

  @authModule.Getter('isUserHasRightsForVouchers')
  private isUserHasRightsForVouchers!: any;

  get isUserLoggedInAndHasRights() {
    return (
      (this.isUserInAzureAdminGroupGetter || (this.isUserGsUser && !!this.zrNummer)) && this.isUserHasRightsForVouchers
    );
  }

  @changeVoucherModule.Action('getChangeVouchers')
  private actionGetchangeVouchers!: any;
  @changeVoucherModule.Getter('getChangeVouchers')
  private changeVouchers!: OdataItems<ChangeVoucher>;
  @changeVoucherModule.Action('getChangeVouchersFacetSearch')
  private actionGetChangeVouchersFacetSearch!: any;
  @changeVoucherModule.Getter('getChangeVouchersSearchParams')
  private changeVouchersSearchParams!: any;
  @changeVoucherModule.Getter('getChangeVouchersIsLoading')
  private changeVouchersIsLoading!: boolean;
  @changeVoucherModule.Action('deleteChangeVoucher')
  private actionDeleteChangeVoucher!: any;
  @changeVoucherModule.Action('updateChangeVoucher')
  private actionUpdateChangeVoucher!: any;

  // TODO (GSP-115) implement save later
  @userDataModule.Action('updateUserData')
  private actionUpdateUserData!: any;
  @userDataModule.Action('getUserData')
  private actionGetUserData!: any;

  @changeVoucherModule.Getter('getChangeVoucherSearchData')
  private getChangeVoucherSearchData!: any;
  @changeVoucherModule.Action('updateChangeVoucherSearchData')
  private actionUpdateChangeVoucherSearchData!: any;

  @changeVoucherModule.Action('getChangeVouchersPdfReport')
  private actionGetChangeVouchersPdfReport!: any;

  @changeVoucherModule.Action('getChangeVouchersExcelReport')
  private actionGetChangeVouchersExcelReport!: any;

  @changeVoucherModule.Getter('isChangeVoucherSearchDataEmpty')
  private isChangeVoucherSearchDataEmpty!: any;

  private searchPayload: any = {};
  private previousTimeoutId: any = 0;
  private chipData: Array<any> = [];

  @Watch('getChangeVoucherSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: ChangeVoucherSearchData, oldVal: ChangeVoucherSearchData) {
    const areObjectsEqual = GeneralUtils.areObjectsEqual(newVal, oldVal, ['isUserInAzureAdminGroup', 'zrNummer']); // decided to ignore 2 props than include logic for them in `search-form-invoice` component

    if (areObjectsEqual) {
      return;
    }
    
    console.log('-----------get ChangeVoucherSearchData-----------on OptionsFilterChanged--------------------------------------  ===>>> newVal  ', newVal, '  isChangeVoucherSearchDataEmpty ', this.isChangeVoucherSearchDataEmpty);
    if (!newVal?.statusType) return; // solve strange `undefined` Bug
    if (!newVal?.intStatusType) return; // solve strange `undefined` Bug

    let payload: any = {
      changeVoucherSearchData: {
        aeaType: newVal.aeaType.map((x) => +x),
        statusType: newVal.statusType.map((x) => +x),
        intStatusType: newVal?.intStatusType?.map((x) => +x),
        receiverNumbers: newVal.receiverNumbers, // mitglied
        senderNumbers: newVal.senderNumbers, //lieferant
        erfassungsdatum: newVal.erfassungsdatum,
        dueDate: newVal.dueDate,
        bruttoNetto: newVal.bruttoNetto,
      },
    };

    this.searchPayload = payload.changeVoucherSearchData;
    localStorage.setItem('changeVoucherSearchData', JSON.stringify(payload.changeVoucherSearchData));

    if (!localStorage.getItem('changeVoucher-filter')) {
      this.changeVouchersSearchParams.filter = ''; // clear Odata filter, cleared here to avoid extra backend query from `@update:options="selectionUpdateOptions"`
    }

    // set correct payload for `download PDF/excel` request
    this.getChangeVoucherSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getChangeVoucherSearchData.isUserInOnlyGs = this.isUserOnlyGs;
    this.getChangeVoucherSearchData.zrNummer = this.getZrNummerDependOnRole();

    // ignore request to backend until rights checked and tabled showed
    // showed use after `localStorage.setItem('changeVoucherSearchData'` to work properly
    if (!this.isUserLoggedInAndHasRights) return;

    payload.changeVoucherSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    payload.changeVoucherSearchData.isUserInOnlyGs = this.isUserOnlyGs;
    if (this.isUserGsUser) {
      payload.changeVoucherSearchData.zrNummer = this.getZrNummerDependOnRole();
    }

    this.changeVouchersSearchParams.dataOption.page = 1; // (GSP-082) go to 1st page, when change filter
    await this.actionGetchangeVouchers(payload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });

    await this.updateChangeVoucherSearchDataBasedOnLastUpdate();
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? (this.zrNummerWithRoles.voucherZrNummer + '') || '-' : this.zrNummer + '';
    return result;
  }

  private geTimeFromLastInvoiceSearchDataUpdateInSeconds() {
    let timeFromLastInvoiceSearchDataUpdateInSeconds = 0;
    if (!localStorage.getItem('changeVoucherSearchDataLastUpdate')) {
      localStorage.setItem('changeVoucherSearchDataLastUpdate', new Date().getTime() + '');
    } else {
      const currentTime = new Date().getTime();
      timeFromLastInvoiceSearchDataUpdateInSeconds =
        (currentTime - +localStorage.getItem('changeVoucherSearchDataLastUpdate')!) / 1000;
      localStorage.setItem('changeVoucherSearchDataLastUpdate', new Date().getTime() + '');
    }

    return timeFromLastInvoiceSearchDataUpdateInSeconds;
  }

  clearAllFilters() {
    localStorage.removeItem('changeVoucherSearchData');
    localStorage.removeItem('changeVoucherSearchDataLastUpdate');
    // (GSP-102) when used only `searchData.defaultData()` on clear the `zrNummer` where dropped and next request was without it returning ALL records
    const zrNummerObj = {
      isUserInAzureAdminGroup: this.isUserInAzureAdminGroupGetter,
      zrNummer: this.getZrNummerDependOnRole(),
    };
    const data = { ...changeVoucherSearchData.defaultData(), ...zrNummerObj };
    // (GSP-102) need this trick with different payload to trigger request in watcher otherwise the old/new object will be considered `EQUAL` and request will not be sent
    const payload = this.getChangeVoucherSearchData.receiverNumbers
      ? Object.assign({}, zrNummerObj)
      : Object.assign({}, data);
      console.log('--------------clear AllFilters--------------action UpdateChangeVoucherSearchData--------------------------------  ===>>> this.isChangeVoucherSearchDataEmpty  ',this.isChangeVoucherSearchDataEmpty);

    this.actionUpdateChangeVoucherSearchData(changeVoucherSearchData.defaultData());
    console.log('--------------clear AllFilters--------------action UpdateChangeVoucherSearchData--------------------------------  ===>>> this.isChangeVoucherSearchDataEmpty  ',this.isChangeVoucherSearchDataEmpty);
    this.resetAllFacetOptionsAndSearch();
  }

  resetAllFacetOptionsAndSearch() {
    this.resetChips();
    this.resetMenuItems();
    this.resetFacetMenu();
    this.changeVouchersSearchParams.andClauseFieldsIds = [];
    this.changeVouchersSearchParams.filter = '';
  }

  get chipDataString() {
    return this.chipData.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
  }

  async created() {
    //GSP-097 temp redirect will be deleted later
    // GSP-107 from 2024-01-24 allow to see `Invoices` for all users (this limitation can be brought back, so commented, not deleted )
    // if(!this.isUserSuperAdmin && !this.isUserOnlyGsAndHasTestZr) {
    //   this.goToZrDocumentsList();
    // }

    await this.actionCanSeeAll().catch((err: any) => {
      logger.error(err);
    });

    if (this.initialRedirect) return;

    localStorage.removeItem('changeVoucherSearchDataLastUpdate');
    await this.actionGetUserData(CONST.emptyGuid).then((result: UserData) => {
      if (result?.changeVoucherSearchData) {
        const changeVoucherSearchData = JSON.parse(result.changeVoucherSearchData);
        this.searchPayload = changeVoucherSearchData.changeVoucherSearchData;
        this.actionUpdateChangeVoucherSearchData(changeVoucherSearchData.changeVoucherSearchData);
        localStorage.setItem('changeVoucher-filter', changeVoucherSearchData.odataFilter);
        if (changeVoucherSearchData.chipDataString) {
          localStorage.setItem('changeVoucher-chipdata', changeVoucherSearchData.chipDataString);
          this.parseLocalStorageChips(changeVoucherSearchData.chipDataString);
        }
      }
    });

    if (this.isUserLoggedInAndHasRights) {
      await this.getChangeVouchers();
    }
  }

  parseLocalStorageChips(chipDataStr: any) {
    const values = chipDataStr.split(',');
    this.changeVouchersSearchParams.andClauseFieldsIds = []; // (GSP-095) avoid duplication in `andClauseFieldsIds` (when return from any other component (any form, zrDocuments list))
    values.forEach((element: string) => {
      const field = element.split(':')[0];
      const word = element.split(':')[1];
      const fieldNameTranslated = this.$i18n.tc(`facet_search.${field}`);
      this.chipData.push({
        chipText: `${fieldNameTranslated}: ${word}`,
        chipField: field,
        searchWord: word,
      });

      this.changeVouchersSearchParams.andClauseFieldsIds.push({
        chipField: field,
        searchWord: word,
      });
    });
  }

  private isMounted = false; // avoid initial extra request in `selectionUpdateOptions`
  async mounted() {
    // var promiseAll = [this.getChangeVouchers()];
    // await Promise.all(promiseAll);
    this.isMounted = true;
  }

  get searchMenuObj() {
    return {
      facetSearch: this.facetSearch,
      menuDisabled: this.menuDisabled,
      menuItems: this.menuItems,
      hasResults: this.hasResults,
      chipData: this.chipData,
    };
  }

  get hasResults() {
    return this.menuItems.length > 0 && !!this.menuItems[0]?.field;
  }

  get mitgliedList() {
    return this.getChangeVoucherSearchData?.receiverNumbers?.join(', ');
  }

  get initialRedirect() {
    return this.isInitialRedirectGetter;
  }

  get tableText() {
    return `${this.changeVouchers.total} Änderungsanzeigen`;
  }

  private async getChangeVouchers() {
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.searchPayload.isUserInOnlyGs = this.isUserOnlyGs;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }
    await this.actionGetchangeVouchers({
      searchParams: this.changeVouchersSearchParams,
      changeVoucherSearchData: this.searchPayload,
    });
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      align?: string;
      class?: string | string[];
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('created'), value: 'created' },
      { text: this.$t('ext_document_date'), value: 'ext_document_date' },
      { text: this.$t('aa_type_id'), value: 'aa_type_id' },
      { text: this.$t('status'), value: 'status', align: 'left' },
      { text: this.$t('int_status'), value: 'int_status' },
      { text: this.$t('id_number'), value: 'reference_id' },
      { text: this.$t('commission_no'), value: 'commission_no' },
      { text: this.$t('due_date'), value: 'due_date' },
      { text: this.$t('amount_gross'), value: 'gross_amount', align: 'right' },
      // { text: this.$t('amount_net'), value: 'net_amount', align: 'right' },
      { text: this.$t('sender'), value: 'zr_number_supplier' },
      { text: this.$t('lieferant_name'), value: 'name_lieferant' }, // NO such field in the `Invoice` database
      { text: this.$t('receiver'), value: 'zr_number_member' },
      { text: this.$t('updated'), value: 'updated_by' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  get image() {
    return {
      src: 'img_changeVoucher.png',
      header: this.$t('title'),
      width: 360,
      height: 200,
    };
  }
  get newTabIcon() {
    return {
      src: 'open-new-tab.svg',
      header: '',
      width: 18,
      height: 18,
    };
  }

  get openAufhebung() {
    return {
      src: 'aufhebung.svg',
      header: '',
      width: 20,
      height: 20,
    };
  }

  get openGreyAufhebung() {
    return {
      src: 'aufhebung_grey.svg',
      header: '',
    };
  }

  get editCV() {
    return {
      src: 'editCV.svg',
      header: '',
    };
  }

  get showingCV() {
    return {
      src: 'showingCV.svg',
      header: '',
      width: 22,
      height: 22,
    };
  }

  get editingCV() {
    return {
      src: 'editingCV.svg',
      header: '',
    };
  }

  onAdd() {
    this.add();
  }

  add() {
    this.$router.push({
      name: ROUTES.changeVoucher,
      params: {
        changeVoucherId: '0',
      },
    });
  }

  createVoucher() {
    this.$router.push({
      name: ROUTES.changeVoucher,
      params: { changeVoucherId: '0' },
    });
  }

  openInvoiceInNewTab(item: ChangeVoucher) {
    const routeData = this.$router.resolve({
      name: ROUTES.invoice,
      query: { doc_number: item.reference_id },
      params: { invoiceId: '0' },
    });
    window.open(routeData.href, '_blank');
  }

  cancelChangeVoucher(item: ChangeVoucher) {
    logger.info('cancelChangeVoucher: ', item);
  }
  editChangeVoucher(editChangeVoucher: ChangeVoucher) {
    this.$router.push({
      name: ROUTES.changeVoucher,
      params: { changeVoucherId: editChangeVoucher.id },
    });
  }
  approveChangeVoucher(item: ChangeVoucher) {
    logger.info('approveChangeVoucher: ', item);
    if (item.status == 0) {
      let grossAmount = item.gross_amount != undefined ? item.gross_amount : 0;
      this.$confirm
        .open(`${this.$t('change_status_dialog_title')}`, `${this.$t('change_status_dialog_text',
          {
            0: this.documentAeaName(item.aa_type_id) + ' (' + item.reference_id + ')',
            1: item.supplierName,
            2: (grossAmount).toLocaleString(this.$i18n.locale, { maximumFractionDigits: 2, minimumFractionDigits: 2
            }) + this.getCurrencySymbol(item.currency.toLowerCase())
          }
        )}`,
        {
          cancelText: this.$t('no_value'),
          okText: this.$t('yes_value'),
        })
        .then(async (response: any) => {
          if (response) {
            item.status = 2;
            await this.actionUpdateChangeVoucher(item).then(() => {
              if (this.zrNummer) {
                this.getChangeVouchers();
              }
            });
          }
        });
    }
  }

  deleteChangeVoucher(editedChangeVoucher: ChangeVoucher) {
    if (editedChangeVoucher.status != 0 && editedChangeVoucher.status != 4) {
      return;
    }
    if (editedChangeVoucher.status == 0) {
      this.$confirm
        .open(`${this.$t('delete_dialog_title')}`, `${this.$t('delete_dialog_text')}`, {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        })
        .then(async (response: any) => {
          if (response) {
            await this.actionDeleteChangeVoucher(editedChangeVoucher.id).then(() => {
              this.getChangeVouchers();
            });
          }
        });
    } else if (editedChangeVoucher.status == 4) {
      this.$confirm
        .open(`${this.$t('archive_dialog_title')}`, `${this.$t('archive_dialog_text')}`, {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        })
        .then(async (response: any) => {
          if (response) {
            editedChangeVoucher.status = 5;
            await this.actionUpdateChangeVoucher(editedChangeVoucher).then(() => {
              if (this.zrNummer) {
                this.getChangeVouchers();
              }
            });
          }
        });
    }
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get getFilterFields() {
    return this.changeVouchersSearchParams.orClauseFieldsIds
      .map((x: any) => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  isVisibleAction(zrstatus : number){
    return  zrstatus == 0 || zrstatus == 4;
  }

  private async changeVouchersUpdateOptions(newVal: any, action: any) {
    console.log('--------------------change VouchersUpdateOptions----------------------------------------  ===>>>   ');
    if (action == 'search') {
      this.searchFacetModeInChangeVouchers(newVal);
    } else if (action == 'reset') {
      this.resetAllFacetOptionsAndSearch();
      this.getChangeVouchersWithParams();
    } else {
      if (this.isMounted && this.changeVouchersIsLoading !== undefined) {
        this.changeVouchersSearchParams.dataOption = newVal;
        this.getChangeVouchersWithParams();
      }
    }
  }

  private async getChangeVouchersWithParams() {
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.searchPayload.isUserInOnlyGs = this.isUserOnlyGs;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }
    await this.actionGetchangeVouchers({
      searchParams: this.changeVouchersSearchParams,
      changeVoucherSearchData: this.searchPayload,
    }).then(async () => {
      await this.updateChangeVoucherSearchDataBasedOnLastUpdate();
    });
  }

  private async updateChangeVoucherSearchDataBasedOnLastUpdate() {
    let timeFromLastInvoiceSearchDataUpdateInSeconds = this.geTimeFromLastInvoiceSearchDataUpdateInSeconds();
    // avoid extra queries to database , but save immediately when clear all filters
    if (timeFromLastInvoiceSearchDataUpdateInSeconds > 1.5) {
      // || this.isInvoiceSearchDataEmpty) {
      await this.updateChangeVoucherSearchDataInDatabase();
    } else {
      clearTimeout(this.previousTimeoutId);
      this.previousTimeoutId = setTimeout(() => this.updateChangeVoucherSearchDataInDatabase(), 2000);
    }
  }

  async updateChangeVoucherSearchDataInDatabase() {
    const userDataPayload = {
      changeVoucherSearchData: {
        changeVoucherSearchData: this.searchPayload,
        odataFilter: localStorage.getItem('changeVoucher-filter') || '',
        chipDataString: this.chipDataString || '',
      },
      isChangeVoucherSearch: true,
    };

    await this.actionUpdateUserData(userDataPayload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private loadingFacet = false;
  private facetSearch = false;
  private searchWord = '';
  private menuDisabled = true;
  private menuItems: any = [];
  /**
   * Make `facet search` in `invoices` to know in which `invoices` fields specified word was found
   * show `No results` if word was not found in any fields
   * @param {KeyboardEvent} e as KeyboardEvent (contains word from input)
   */
  private searchFacetModeInChangeVouchers(e: any): void {
    const searchWord: string = e.filter;

    let selectedListItemId = document.querySelector('.v-list-item--highlighted .v-list-item__title')?.id;
    console.log('----search FacetModeInChangeVouchers--------------------------------------------------------  ===>>>selectedListItemId   ', selectedListItemId);
    if (selectedListItemId != undefined) {
      let menuItem = this.menuItems.find((x: any) => x.field == selectedListItemId);
      this.facetSearch = false;
      this.searchInRealObjects(menuItem);
      return;
    }
    this.searchWord = searchWord;
    // if user press "enter" key again then make search in all fields
    if (this.facetSearch && this.menuItems.length != 0) {
      this.facetSearch = false;
      // return;
    }
    // handle facet search for words more than 2 letters
    if (searchWord?.length >= 2) {
      // const { start, end } = this.getStartEndPeriodForSearch();
      this.loadingFacet = true;
      this.makeFacetSearch(searchWord)
        .then((result: any) => {
          this.formatResultFromSearch(result);
          this.facetSearch = true;
          this.menuDisabled = false;
        })
        .finally(() => {
          this.loadingFacet = false;
        });
    } else if (searchWord?.trim().length === 0) {
      // load all invoices if press `Enter` with clear input
      this.getChangeVouchersWithParams();
    }
  }

  searchInRealObjects(item: any) {
    this.searchInRealChangeVouchers(item);
  }

  searchInRealChangeVouchers(item: any) {
    const searchParams = this.changeVouchersSearchParams; //this.searchParams;

    searchParams.andClauseFieldsIds.push({
      chipField: item.field,
      searchWord: this.searchWord,
    });
    if (item.field === 'all') {
      // searchParams.andClauseFieldsIds.push({
      //   chipField: item.field,
      //   searchWord: this.searchWord,
      // });
    }
    if (this.searchWord?.length >= 2 && item.title.length > 0) {
      // load all invoices if clear the input
      this.actionGetchangeVouchers({ searchParams, changeVoucherSearchData: this.getChangeVoucherSearchData })
        .then((result: any) => {
          this.menuDisabled = true;
          if (item.field != 'all') {
            this.chipData.push({
              chipText: `${item.fieldTranslated}: ${this.searchWord}`,
              chipField: item.field,
              searchWord: this.searchWord,
            });
            localStorage.setItem('changeVoucher-chipdata', this.chipDataString);
            this.updateChangeVoucherSearchDataBasedOnLastUpdate();
          }
        })
        .finally(() => {
          this.resetFacetMenu();
        });
    }
  }

  async makeFacetSearch(searchWord: any) {
    console.log('---------CV--------------make FacetSearch-------------------------------------  ===>>>   ');
    let payload = {
      searchWord: searchWord,
      chipDataString: this.chipDataString,
    };

    // (GSP-102) always set correct payload for `ZrNummer` for GsUser to avoid show/search all records
    this.getChangeVoucherSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getChangeVoucherSearchData.isUserInOnlyGs = this.isUserOnlyGs;
    this.getChangeVoucherSearchData.zrNummer = this.getZrNummerDependOnRole();
    payload = { ...this.getChangeVoucherSearchData, ...payload };
    return this.actionGetChangeVouchersFacetSearch(payload);
  }

  formatResultFromSearch(result: any) {
    const fieldsWithResult = Object.keys(result).filter((key) => result[key] > 0);
    this.resetMenuItems();
    fieldsWithResult.forEach((element: string) => {
      const fieldName = element.charAt(0).toUpperCase() + element.slice(1);

      const fieldNameTranslated = this.$i18n.tc(`facet_search.${fieldName}`);
      this.menuItems.push({
        title: `${fieldName}: ${this.searchWord} (${result[element]})`,
        titleTranslated: `${fieldNameTranslated}: ${this.searchWord} (${result[element]})`,
        fieldTranslated: fieldNameTranslated,
        field: fieldName,
      });
    });
    if (fieldsWithResult.length === 0) {
      this.menuItems.push({
        titleTranslated: this.$i18n.tc('facet_search.no_results'),
      });
      return;
    }
    this.menuItems.unshift({
      titleTranslated: this.$i18n.tc('facet_search.all'),
      field: 'all',
      title: 'All',
    });
  }

  private resetMenuItems() {
    this.menuItems = [];
  }
  private resetChips() {
    this.chipData = [];
  }

  resetFacetMenu() {
    this.menuDisabled = true;
    this.facetSearch = false;
    this.searchWord = '';
  }

  private documentAeaName(value: number) {
    let i18key = changevoucherTypeList.find((x) => x.index == value)?.i18nKey;
    let formattedResult = i18key ? `${this.$t(i18key)}` : value;
    return formattedResult;
  }

  get statuses() {
    return {
      0: { text: this.$t('changevoucherStatusType.draft'), class: 'gray' },
      1: { text: this.$t('changevoucherStatusType.waiting'), class: 'blue' },
      2: { text: this.$t('changevoucherStatusType.submitted'), class: 'yellow' },
      3: { text: this.$t('changevoucherStatusType.booked'), class: 'green' },
      4: { text: this.$t('changevoucherStatusType.rejected'), class: 'red' },
      5: { text: this.$t('changevoucherStatusType.archive'), class: 'darkgray' },
    };
  }

  get internalStatuses() {
    return {
      0: { text: this.$t('voucher_internal_status.open'), class: 'gray' },
      // 1: { text: this.$t('voucher_internal_status.waiting'), class: 'blue' },
      2: { text: this.$t('voucher_internal_status.resubmitted'), class: 'yellow' },
      3: { text: this.$t('voucher_internal_status.booked'), class: 'green' },
    };
  }

  get isAllFiltersEmpty() {
    return this.isChangeVoucherSearchDataEmpty && !this.changeVouchersSearchParams.filter && !this.chipDataString;
  }

  removeChip(chipText: any) {
    const index = this.chipData.findIndex((x) => x.chipText === chipText);
    this.chipData.splice(index, 1);
    const indexOdata = this.changeVouchersSearchParams.andClauseFieldsIds.findIndex(
      (x: any) => x.chipText === chipText
    );
    this.changeVouchersSearchParams.andClauseFieldsIds.splice(index, 1);

    this.menuItems = [];
    this.menuDisabled = true;
    this.getChangeVouchersWithParams();
  }

  // on prod for some reason no request for `zrDocuments` and `zrNummer` is empty (load all records but should load 0)
  setZrNummerInPayloadIfEmpty() {
    // set correct payload for `download PDF/excel` request
    this.getChangeVoucherSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getChangeVoucherSearchData.zrNummer = this.getZrNummerDependOnRole();
  }

  pdfDownload() {
    this.setZrNummerInPayloadIfEmpty();
    let payload = this.getChangeVoucherSearchData || {};
    payload.searchTerm = this.changeVouchersSearchParams.filter;
    payload.chipDataString = this.chipDataString;
    payload.sortingForReport = DefaultBackendHelper.returnSqlOrderByFromOdata(
      this.changeVouchersSearchParams.dataOption
    );
    payload.DateTimeTitle =
      this.$i18n.locale == 'de' ? new Date().toLocaleString('de') : new Date().toLocaleString('en');
    payload.lang = this.$i18n.locale;
    this.searchPayload = payload.changeVoucherSearchData;

    this.actionGetChangeVouchersPdfReport(payload);
  }

  excelDownload() {
    this.setZrNummerInPayloadIfEmpty();
    let payload = this.getChangeVoucherSearchData || {};
    payload.searchTerm = this.getChangeVoucherSearchData?.filter;
    payload.chipDataString = this.chipDataString;
    payload.sortingForReport = DefaultBackendHelper.returnSqlOrderByFromOdata(
      this.getChangeVoucherSearchData.dataOption
    );
    payload.DateTimeTitle =
      this.$i18n.locale == 'de' ? new Date().toLocaleString('de') : new Date().toLocaleString('en');
    payload.lang = this.$i18n.locale;
    this.searchPayload = payload.changeVoucherSearchData;
    this.actionGetChangeVouchersExcelReport(payload);
  }

  getCurrencySymbol(currency: any) {
    let currencySymbol: string = '';
    if (currency == 'euro' || currency == 'eur' || currency == '') {
      currencySymbol = ' €';
    } else if (currency == 'chf') {
      currencySymbol = ' ₣';
    } else if (currency == 'usd') {
      currencySymbol = ' $';
    }

    return currencySymbol;
  }
}
