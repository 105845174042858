import { BackendHelper, DefaultBackendHelper } from './../../../shared/utils/backendHelper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import invSearchData, { InvoiceSearchData } from '@/shared/model/smallPayloadModels/invoiceSearchData';
import { ROUTES } from '@/router/routesEnum';
import DateField from '@/components/_common/date-field/DateField.vue';
import { CONST } from '@/shared/utils/Constants';
import { UserData } from '@/shared/model/userData';
import invoiceSearchData from '@/shared/model/smallPayloadModels/invoiceSearchData';
import { documentTypeList } from '@/shared/model/smallPayloadModels/documentType';
import GeneralUtils from '@/shared/utils/generalUtils';
import SupplierTooltip from '../zr-documents/search-form/supplier-tooltip/supplier-tooltip.vue';

const logger = new Logger('login');
const invoiceModule = namespace('invoice');
const authModule = namespace('auth');
const userDataModule = namespace('userData');

@Component({
  components: { D4yTable, DateField, SupplierTooltip },
})
export default class InvoicesComponent extends Vue {
  @authModule.Action('canSeeAll')
  private actionCanSeeAll!: any;
  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserSuperAdmin')
  private isUserSuperAdmin!: any;
  @authModule.Getter('isUserOnlyGsAndHasTestZr')
  private isUserOnlyGsAndHasTestZr!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;
  @authModule.Getter('isUserSupport')
  private isUserSupport!: any;
  @authModule.Getter('isInitialRedirect')
  private isInitialRedirectGetter!: any;
  @authModule.Getter('supplierTooltipInfo')
  private supplierTooltipInfo!: any;
  @authModule.Getter('isUserHasRightsForInvoices')
  private isUserHasRightsForInvoices!: any;
  @authModule.Getter('isProductionEnv')
  private isProductionEnv!: any;
  @authModule.Getter('isUserHasRightsForVouchers')
  private isUserHasRightsForVouchers!: any;


  @invoiceModule.Action('getInvoices')
  private actionGetInvoices!: any;
  @invoiceModule.Action('getInvoicesFacetSearch')
  private actionGetInvoicesFacetSearch!: any;
  @invoiceModule.Action('getInvoicesExcelReport')
  private actionGetInvoicesExcelReport!: any;
  @invoiceModule.Action('getInvoicesPdfReport')
  private actionGetInvoicesPdfReport!: any;
  @invoiceModule.Getter('getInvoices')
  private getInvoices!: any;
  @invoiceModule.Getter('getInvoicesIsLoading')
  private invoicesIsLoading!: boolean;
  @invoiceModule.Getter('getInvoicesSearchParams')
  private invoicesSearchParams!: any;
  @invoiceModule.Getter('isInvoiceSearchDataEmpty')
  private isInvoiceSearchDataEmpty!: any;

  @invoiceModule.Action('updateInvoiceSearchData')
  private actionUpdateInvoiceSearchData!: any;
  @invoiceModule.Getter('getInvoiceSearchData')
  private getInvoiceSearchData!: any;
  @invoiceModule.Getter('getInvoiceExcelIsLoading')
  private getInvoiceExcelIsLoading!: any;
  @invoiceModule.Getter('getInvoicePdfIsLoading')
  private getInvoicePdfIsLoading!: any;

  @userDataModule.Action('updateUserData')
  private actionUpdateUserData!: any;
  @userDataModule.Action('getUserData')
  private actionGetUserData!: any;

  private previousTimeoutId: any = 0;
  private searchPayload: any = {};
  @Watch('getInvoiceSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: InvoiceSearchData, oldVal: InvoiceSearchData) {
    console.log('@Watch!!!!!!!!!!!!!!!!!!!!!!--------get InvoiceSearchData---------------------->>>:   ');
    const areObjectsEqual = GeneralUtils.areObjectsEqual(newVal, oldVal, ['isUserInAzureAdminGroup', 'zrNummer']); // decided to ignore 2 props than include logic for them in `search-form-invoice` component
    console.log('areObjectsEqual :>> ', areObjectsEqual);
    logger.debug(`----filter:${oldVal}->${newVal}`);
    logger.log('newVal :>> ', newVal);
    logger.log('oldVal :>> ', oldVal);

    if (areObjectsEqual) {
      return;
    }

    if (!newVal?.documentType) return; // solve strange `undefined` Bug

    let payload: any = {
      invoiceSearchData: {
        documentType: newVal.documentType.map((x) => +x),
        receiverNumbers: newVal.receiverNumbers, // mitglied
        senderNumbers: newVal.senderNumbers, //lieferant
        datum: newVal.datum,
        dueDate: newVal.dueDate,
        endOfWeekDate: newVal.endOfWeekDate,
        bruttoNetto: newVal.bruttoNetto,
      },
    };
    this.searchPayload = payload.invoiceSearchData;
    localStorage.setItem('invoiceSearchData', JSON.stringify(payload.invoiceSearchData));

    if (!localStorage.getItem('invoices-filter')) {
      this.invoicesSearchParams.filter = ''; // clear Odata filter, cleared here to avoid extra backend query from `@update:options="selectionUpdateOptions"`
    }

    // set correct payload for `download PDF/excel` request
    this.getInvoiceSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getInvoiceSearchData.zrNummer = this.getZrNummerDependOnRole();

    // ignore request to backend until rights checked and tabled showed
    // showed use after `localStorage.setItem('invoiceSearchData'` to work properly
    if (!this.isUserLoggedInAndHasRights) return;

    payload.invoiceSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      console.log('this.zrNummer onOptionsFilterChanged :>> ', this.zrNummer);
      payload.invoiceSearchData.zrNummer = this.getZrNummerDependOnRole();
    }

    this.invoicesSearchParams.dataOption.page = 1; // (GSP-082) go to 1st page, when change filter
    await this.actionGetInvoices(payload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });

    await this.updateInvoiceSearchDataBasedOnLastUpdate();
  }

  get isUserLoggedInAndHasRights() {
    return (
      (this.isUserInAzureAdminGroupGetter || (this.isUserGsUser && !!this.zrNummer)) && this.isUserHasRightsForInvoices
    );
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? (this.zrNummerWithRoles.invoiceZrNummer + '') || '-' : this.zrNummer + '';
    return result;
  }

  private async updateInvoiceSearchDataBasedOnLastUpdate() {
    let timeFromLastInvoiceSearchDataUpdateInSeconds = this.geTimeFromLastInvoiceSearchDataUpdateInSeconds();
    // avoid extra queries to database , but save immediately when clear all filters
    if (timeFromLastInvoiceSearchDataUpdateInSeconds > 1.5) {
      // || this.isInvoiceSearchDataEmpty) {
      await this.updateInvoiceSearchDataInDatabase();
    } else {
      clearTimeout(this.previousTimeoutId);
      this.previousTimeoutId = setTimeout(() => this.updateInvoiceSearchDataInDatabase(), 2000);
    }
  }

  private geTimeFromLastInvoiceSearchDataUpdateInSeconds() {
    let timeFromLastInvoiceSearchDataUpdateInSeconds = 0;
    if (!localStorage.getItem('invoiceSearchDataLastUpdate')) {
      localStorage.setItem('invoiceSearchDataLastUpdate', new Date().getTime() + '');
    } else {
      const currentTime = new Date().getTime();
      timeFromLastInvoiceSearchDataUpdateInSeconds =
        (currentTime - +localStorage.getItem('invoiceSearchDataLastUpdate')!) / 1000;
      localStorage.setItem('invoiceSearchDataLastUpdate', new Date().getTime() + '');
    }
    console.log('timeFromLastUpdate :>> ', timeFromLastInvoiceSearchDataUpdateInSeconds);
    return timeFromLastInvoiceSearchDataUpdateInSeconds;
  }

  async updateInvoiceSearchDataInDatabase() {
    const userDataPayload = {
      invoiceSearchData: {
        invoiceSearchData: this.searchPayload,
        odataFilter: localStorage.getItem('invoices-filter') || '',
        chipDataString: this.chipDataString || '',
      },
      isInvoiceSearch: true,
    };

    await this.actionUpdateUserData(userDataPayload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });
  }

  get mitgliedList() {
    return this.getInvoiceSearchData?.receiverNumbers?.join(', ');
  }

  get initialRedirect() {
    return this.isInitialRedirectGetter;
  }

  get tableText() {
    return `${this.getInvoices.total} Invoices`;
  }

  get isAllFiltersEmpty() {
    return this.isInvoiceSearchDataEmpty && !this.invoicesSearchParams.filter && !this.chipDataString;
  }

  async created() {
    //GSP-097 temp redirect will be deleted later
    // GSP-107 from 2024-01-24 allow to see `Invoices` for all users (this limitation can be brought back, so commented, not deleted )
    // if(!this.isUserSuperAdmin && !this.isUserOnlyGsAndHasTestZr) {
    //   this.goToZrDocumentsList();
    // }

    await this.actionCanSeeAll().catch((err: any) => {
      logger.error(err);
    });

    if (this.initialRedirect) return;
    console.log('created invoices :>> ');

    localStorage.removeItem('invoiceSearchDataLastUpdate'); // reset (make null) localsStorage update time on reload
    await this.actionGetUserData(CONST.emptyGuid).then((result: UserData) => {
      if (result?.invoiceSearchData) {
        //logger.log('-------------------invoiceSearchData-----------------------action GetUserData result:>> ', result);
        const invoiceSearchData = JSON.parse(result.invoiceSearchData);
        this.searchPayload = invoiceSearchData.invoiceSearchData;
        console.log('--created-------invoice action action GetUserData--------------------->>>:   ', invoiceSearchData);
        this.actionUpdateInvoiceSearchData(invoiceSearchData.invoiceSearchData);
        localStorage.setItem('invoices-filter', invoiceSearchData.odataFilter);
        if (invoiceSearchData.chipDataString) {
          localStorage.setItem('invoices-chipdata', invoiceSearchData.chipDataString);
          this.parseLocalStorageChips(invoiceSearchData.chipDataString);
        }
      }
      console.log('--created-------invoice action action GetUserData------NO DATA--------------->>>:   ');
    });

    if (this.isUserLoggedInAndHasRights) {
      await this.getInvoicesData();
    }
  }

  goToZrDocumentsList() {
    this.$router.push({
      name: ROUTES.home,
    });
  }

  parseLocalStorageChips(chipDataStr: any) {
    const values = chipDataStr.split(',');
    this.invoicesSearchParams.andClauseFieldsIds = []; // (GSP-095) avoid duplication in `andClauseFieldsIds` (when return from any other component (any form, zrDocuments list))
    values.forEach((element: string) => {
      const field = element.split(':')[0];
      const word = element.split(':')[1];
      const fieldNameTranslated = this.$i18n.tc(`facet_search.${field}`);
      this.chipData.push({
        chipText: `${fieldNameTranslated}: ${word}`,
        chipField: field,
        searchWord: word,
      });

      this.invoicesSearchParams.andClauseFieldsIds.push({
        chipField: field,
        searchWord: word,
      });
    });
  }

  private isMounted = false; // avoid initial extra request in `selectionUpdateOptions`
  async mounted() {
    this.isMounted = true;
  }

  private async getInvoicesData() {
    // don't take getter value in this case
    if (localStorage.getItem('invoices-filter')) {
      this.invoicesSearchParams.filter = localStorage.getItem('invoices-filter');
    }
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }

    const payload = { searchParams: this.invoicesSearchParams, invoiceSearchData: this.searchPayload }; // sent search request from localStorage on reload
    await this.actionGetInvoices(payload)
      .then((result: any) => {
        logger.log('result :>> ', result);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  get items() {
    return this.getInvoices.items;
  }
  get invoicesLoaded() {
    return this.invoicesIsLoading !== undefined;
  }

  private documentTypeName(value: number) {
    let i18key = documentTypeList.find((x) => x.index == value)?.i18nKey;
    let formattedResult = i18key ? `${this.$t(i18key)} (${value})` : value;
    return formattedResult;
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      align?: string;
      class?: string | string[];
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('investment_date'), value: 'externe_belegdatum' },
      { text: this.$t('book_date'), value: 'belegdatum' },
      { text: this.$t('due_date'), value: 'faelligkeitsdatum' },
      { text: this.$t('end_week_date'), value: 'wochenabschluss_datum' },
      { text: this.$t('invoice_number'), value: 'externe_belegnr' },
      { text: this.$t('id_number'), value: 'ident_nr' },
      { text: this.$t('commission_number'), value: 'kommissionsnr' },

      { text: this.$t('amount_gross'), value: 'brutto', align: 'right' },
      { text: this.$t('amount_net'), value: 'netto', align: 'right' },
      { text: this.$t('sender'), value: 'zr_nr_kreditor' }, // `Lieferant` // was `SenderNumber`
      { text: this.$t('lieferant_name'), value: 'name_lieferant' }, // NO such field in the `Invoice` database
      { text: this.$t('receiver'), value: 'zr_nr_debitor' }, // `Mitglied` // was `ReceiverNumber`
      { text: this.$t('document_type'), value: 'belegtyp' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  private openInvoice(item: any) {
    logger.log('openInvoice item :>> ', item);

    this.$router.push({
      name: ROUTES.invoice,
      params: { invoiceId: item.id },
    });
  }

  clearAllFilters() {
    localStorage.removeItem('invoiceSearchData');
    localStorage.removeItem('invoiceSearchDataLastUpdate');
    // (GSP-102) when used only `searchData.defaultData()` on clear the `zrNummer` where dropped and next request was without it returning ALL records
    const zrNummerObj = {
      isUserInAzureAdminGroup: this.isUserInAzureAdminGroupGetter,
      zrNummer: this.getZrNummerDependOnRole(),
    };
    const data = { ...invoiceSearchData.defaultData(), ...zrNummerObj };
    // (GSP-102) need this trick with different payload to trigger request in watcher otherwise the old/new object will be considered `EQUAL` and request will not be sent
    const payload = this.getInvoiceSearchData.receiverNumbers
      ? Object.assign({}, zrNummerObj)
      : Object.assign({}, data);

    console.log('---clearAllFilters------invoice action UpdateInvoiceSearchData--------------------->>>:   ');
    this.actionUpdateInvoiceSearchData(invoiceSearchData.defaultData());

    this.resetAllFacetOptionsAndSearch();
  }

  private async selectionUpdateOptions(newVal: any, action: any) {
    console.log('selectionUpdateOptions :>> ', newVal);
    console.log('action :>> ', action);
    // this.invoicesSearchParams.dataOption = newVal;
    // this.invoicesSearchParams.filter = newVal.filter;
    // if (newVal.filter !== undefined) {
    //   localStorage.setItem('invoices-filter', newVal.filter);
    // }
    // if (this.isMounted && this.invoicesIsLoading !== undefined) this.getInvoicesWithParams();
    if (action == 'search') {
      this.searchFacetModeInInvoices(newVal);
    } else if (action == 'reset') {
      this.resetAllFacetOptionsAndSearch();
      this.getInvoicesWithParams();
    } else {
      if (this.isMounted && this.invoicesIsLoading !== undefined) {
        this.invoicesSearchParams.dataOption = newVal;
        // this.invoicesSearchParams.filter = newVal.filter;
        this.getInvoicesWithParams();
      }
    }
  }
  
  private async getInvoicesWithParams() {
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }
    await this.actionGetInvoices({
      searchParams: this.invoicesSearchParams,
      invoiceSearchData: this.searchPayload,
    }).then(async () => {
      await this.updateInvoiceSearchDataBasedOnLastUpdate();
    });
  }

  get isHasSearchData() {
    return this.getInvoices.total === 0 || this.invoicesIsLoading || this.isSearchDataHasNoValues();
  }

  isSearchDataHasNoValues() {
    // let values = Object.values(this.getInvoiceSearchData);
    // let result = values.some((x: any) => (x + '').length > 0);
    // return !result;
    return false;
  }

  excelDownload() {
    let payload = this.getInvoiceSearchData || {};
    payload.searchTerm = this.invoicesSearchParams?.filter;
    payload.chipDataString = this.chipDataString;
    payload.sortingForReport = DefaultBackendHelper.returnSqlOrderByFromOdata(this.invoicesSearchParams.dataOption);
    payload.DateTimeTitle =
      this.$i18n.locale == 'de' ? new Date().toLocaleString('de') : new Date().toLocaleString('en');
    payload.lang = this.$i18n.locale;
    if (this.isUserOnlyGs) {
      payload.zrNummer = this.getZrNummerDependOnRole();
    }
    this.searchPayload = payload.invoiceSearchData;
    this.actionGetInvoicesExcelReport(payload);
  }

  //#region Facet
  private facetSearch = false;
  private menuDisabled = true;
  private searchWord = '';
  private menuItems: any = [];
  private chipData: Array<any> = [];

  get chipDataString() {
    return this.chipData.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
  }

  removeChip(chipText: any) {
    const index = this.chipData.findIndex((x) => x.chipText === chipText);
    this.chipData.splice(index, 1);
    const indexOdata = this.invoicesSearchParams.andClauseFieldsIds.findIndex((x: any) => x.chipText === chipText);
    this.invoicesSearchParams.andClauseFieldsIds.splice(index, 1);

    this.menuItems = [];
    this.menuDisabled = true;
    this.getInvoicesWithParams();
  }

  private loadingFacet = false;
  /**
   * Make `facet search` in `invoices` to know in which `invoices` fields specified word was found
   * show `No results` if word was not found in any fields
   * @param {KeyboardEvent} e as KeyboardEvent (contains word from input)
   */
  private searchFacetModeInInvoices(e: any): void {
    const searchWord: string = e.filter;

    let selectedListItemId = document.querySelector('.v-list-item--highlighted .v-list-item__title')?.id;
    if (selectedListItemId != undefined) {
      let menuItem = this.menuItems.find((x: any) => x.field == selectedListItemId);
      this.facetSearch = false;
      this.searchInRealObjects(menuItem);
      return;
    }
    this.searchWord = searchWord;
    // if user press "enter" key again then make search in all fields
    if (this.facetSearch && this.menuItems.length != 0) {
      this.facetSearch = false;
      // return;
    }
    // handle facet search for words more than 2 letters
    if (searchWord?.length >= 2) {
      // const { start, end } = this.getStartEndPeriodForSearch();
      this.loadingFacet = true;
      this.makeFacetSearch(searchWord)
        .then((result: any) => {
          this.formatResultFromSearch(result);
          this.facetSearch = true;
          this.menuDisabled = false;
        })
        .finally(() => {
          this.loadingFacet = false;
        });
    } else if (searchWord?.trim().length === 0) {
      // load all invoices if press `Enter` with clear input
      this.getInvoicesWithParams();
    }
  }

  async makeFacetSearch(searchWord: any) {
    let payload = {
      searchWord: searchWord,
      chipDataString: this.chipDataString,
    };

    // (GSP-102) always set correct payload for `ZrNummer` for GsUser to avoid show/search all records
    this.getInvoiceSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getInvoiceSearchData.zrNummer = this.getZrNummerDependOnRole();
    payload = { ...this.getInvoiceSearchData, ...payload };

    return this.actionGetInvoicesFacetSearch(payload);
  }

  searchInRealObjects(item: any) {
    this.searchInRealInvoices(item);
  }

  /**
   * Make the actual search in the `invoices` table with returning `invoices` based on results from `facet search`
   * @param {object} Menu item `object` with title(shown in menu) and field(backend field name) properties
   */
  searchInRealInvoices(item: any) {
    const searchParams = this.invoicesSearchParams; //this.searchParams;
    console.log('----searchInRealObjects-----invoices SearchParams action action GetUserData--------------------->>>:   ', item);
    searchParams.andClauseFieldsIds.push({
      chipField: item.field,
      searchWord: this.searchWord,
    });
    if (item.field === 'all') {
      // searchParams.andClauseFieldsIds.push({
      //   chipField: item.field,
      //   searchWord: this.searchWord,
      // });
    }
    if (this.searchWord?.length >= 2 && item.title.length > 0) {
      // load all invoices if clear the input
      this.actionGetInvoices({ searchParams, invoiceSearchData: this.getInvoiceSearchData })
        .then((result: any) => {
          this.menuDisabled = true;
          if (item.field != 'all') {
            this.chipData.push({
              chipText: `${item.fieldTranslated}: ${this.searchWord}`,
              chipField: item.field,
              searchWord: this.searchWord,
            });
            localStorage.setItem('invoices-chipdata', this.chipDataString);
            this.updateInvoiceSearchDataBasedOnLastUpdate();
          }
        })
        .finally(() => {
          this.resetFacetMenu();
        });
    }
  }

  formatResultFromSearch(result: any) {
    const fieldsWithResult = Object.keys(result).filter((key) => result[key] > 0);
    this.resetMenuItems();
    fieldsWithResult.forEach((element: string) => {
      const fieldName = element.charAt(0).toUpperCase() + element.slice(1);

      const fieldNameTranslated = this.$i18n.tc(`facet_search.${fieldName}`);
      this.menuItems.push({
        title: `${fieldName}: ${this.searchWord} (${result[element]})`,
        titleTranslated: `${fieldNameTranslated}: ${this.searchWord} (${result[element]})`,
        fieldTranslated: fieldNameTranslated,
        field: fieldName,
      });
    });
    if (fieldsWithResult.length === 0) {
      this.menuItems.push({
        titleTranslated: this.$i18n.tc('facet_search.no_results'),
      });
      return;
    }
    this.menuItems.unshift({
      titleTranslated: this.$i18n.tc('facet_search.all'),
      field: 'all',
      title: 'All',
    });
  }

  /**
    Should reset `menuItems` before add result from the next search otherwise the previous result will be summed with current result
    */
  private resetMenuItems() {
    this.menuItems = [];
  }
  private resetChips() {
    this.chipData = [];
  }

  resetFacetMenu() {
    this.menuDisabled = true;
    this.facetSearch = false;
    this.searchWord = '';
  }

  resetAllFacetOptionsAndSearch() {
    this.resetChips();
    this.resetMenuItems();
    this.resetFacetMenu();
    this.invoicesSearchParams.andClauseFieldsIds = [];
    this.invoicesSearchParams.filter = '';
  }

  get searchMenuObj() {
    return {
      facetSearch: this.facetSearch,
      menuDisabled: this.menuDisabled,
      menuItems: this.menuItems,
      hasResults: this.hasResults,
      chipData: this.chipData,
    };
  }

  pdfDownload() {
    let payload = this.getInvoiceSearchData || {};
    console.log('------------pdfDownload-------payload----------->>>:   ', payload);
    payload.searchTerm = this.invoicesSearchParams.filter;
    payload.chipDataString = this.chipDataString;
    payload.sortingForReport = DefaultBackendHelper.returnSqlOrderByFromOdata(this.invoicesSearchParams.dataOption);
    payload.DateTimeTitle =
      this.$i18n.locale == 'de' ? new Date().toLocaleString('de') : new Date().toLocaleString('en');
    payload.lang = this.$i18n.locale;
    if (this.isUserOnlyGs) {
      payload.zrNummer = this.getZrNummerDependOnRole();
    }
    this.searchPayload = payload.invoiceSearchData;
    this.actionGetInvoicesPdfReport(payload);
  }

  get hasResults() {
    return this.menuItems.length > 0 && !!this.menuItems[0]?.field;
  }

  getCurrencySymbol(currency: any) {
    let currencySymbol: string = '';
    if (currency == 'euro' || currency == 'eur' || currency == '') {
      currencySymbol = ' €';
    } else if (currency == 'chf') {
      currencySymbol = ' ₣';
    } else if (currency == 'usd') {
      currencySymbol = ' $';
    }

    return currencySymbol;
  }

  get createAenderungsanzeigen() {
    return {
      src: 'aea_create_from_invoice.svg',
      header: '',
      width: 32,
      height: 32,
    };
  }

  get noCreateAenderungsanzeigen() {
    return {
      src: 'aea_create_not active.svg',
      header: '',
      width: 32,
      height: 32,
    };
  }

  createVoucher(item: any) {
    console.log('----------create Voucher-----------------item.externe_belegnr--------  >>>:/*', item.externe_belegnr, '*/  item.ident_nr***', item.ident_nr?.toString().trim(), '***', ' item belegtyp ', item.belegtyp);
    console.log('---------------------(item.belegtyp == 4 || item.belegtyp ==5 || item.belegtyp == 6)--------------  >>>:', (item.belegtyp == 4 || item.belegtyp ==5 || item.belegtyp == 6));
    if (item.belegtyp == 4 || item.belegtyp ==5 || item.belegtyp == 6)
      return;
    this.$router.push({
      name: ROUTES.changeVoucher,
      params: { changeVoucherId: '0', senderNumber: item.zrNummer?.length != 0 ? item.zrNummer : item.zr_nr_kreditor, documentNumber: item.ident_nr, externalDocumentReference: item.externe_belegnr, receiverNumber: item.zr_nr_debitor, dms_nr: item.dms_nr },
    });
  }

  //#endregion
}
